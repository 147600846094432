import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { getUser, uploadDocs } from '../../../features/usersSlice'
import { RootState } from '../../../store'
import DocumentContainer from './DocumentContainer'

interface DocumentsProps {
  btnLoading: boolean
  setBtnLoading: (value: boolean) => any
}

const Documents: React.FC<DocumentsProps> = ({
  btnLoading,
  setBtnLoading,
}: DocumentsProps) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.users)

  const fileSelectedHandler = async (e: any, type: string) => {
    e.preventDefault()
    if (e.target.files[0]) {
      setBtnLoading(true)
      const response = await dispatch(
        uploadDocs({
          acceptedFile: e.target.files[0],
          fileName: e.target.files[0].name,
          type: type,
          userId: user?.userId,
        }),
      )
      if (response && response.payload === 200) {
        await dispatch(getUser({ id: user?.userId }))
      }
      setBtnLoading(false)
    }
  }

  const {
    taxWithholdingAndReportingDocuments,
    financialAdvisorDocuments,
    accreditationDocuments,
    internalDocuments,
    taxDocuments,
    userUploadedDocuments,
  } = user || {}
  return (
    <div className="row g-3 d-flex flex-column">
      <DocumentContainer
        label="Tax Withholding and Reporting"
        documents={taxWithholdingAndReportingDocuments}
        btnLoading={btnLoading}
        handleChange={(e: any) => fileSelectedHandler(e, 'TAX')}
      />
      <DocumentContainer
        label="Financial Advisor"
        documents={financialAdvisorDocuments}
        btnLoading={btnLoading}
        handleChange={(e: any) => fileSelectedHandler(e, 'FA')}
      />
      <DocumentContainer
        label="Accreditation Documents"
        documents={accreditationDocuments}
        btnLoading={btnLoading}
        handleChange={(e: any) => fileSelectedHandler(e, 'AI')}
      />
      <DocumentContainer
        label="Internal Documents"
        documents={internalDocuments}
        btnLoading={btnLoading}
        handleChange={(e: any) => fileSelectedHandler(e, 'INTERNAL')}
      />
      <DocumentContainer
        label="User Tax Documents"
        documents={taxDocuments}
        btnLoading={btnLoading}
        handleChange={(e: any) => fileSelectedHandler(e, 'USER_TAX')}
      />
      <DocumentContainer
        label="User Uploaded Documents"
        documents={userUploadedDocuments}
        btnLoading={btnLoading}
        handleChange={(e: any) => fileSelectedHandler(e, 'USER_UPLOADED')}
      />
    </div>
  )
}

export default Documents
