import React, { useCallback, useMemo, useState } from 'react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router'
import { RootState } from '../../store'
import Spinner from '../../components/Spinner'
import PageLayout from '../../layout/PageLayout'
import { CButton, CLoadingButton } from '@coreui/react-pro'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import { getCountries } from '../../features/commonSlice'
import {
  getUser,
  resetError,
  saveUser,
  getRIAFirm,
  getRIALinkedClients,
  getParentRIAUser,
  getTrustedContact,
  saveTrustedContact,
  getAllSectors,
  getUserSectors,
  deleteUser,
  resetUser,
  getUsers
} from '../../features/usersSlice'
import {
  BasicInformation,
  UserEntities,
  Tag,
  VerifyModal,
  TabControl,
  LinqtoBucks,
  Documents,
  Address,
} from './components'
import _ from 'lodash'
import AddTransactionModal from './components/AddTransactionModal'
import AddPurchaseCreditModal from './components/AddPurchaseCreditModal'
import Alert from '../../components/Alert'
import LinkItems from '../../components/LinkItem/LinkItems'
import UserMFATab from './components/UserMFA/UserMFATab'
import DeleteUserModal from './components/DeleteUserModal'
import UserHoldings from './components/UserHoldings'
import UserSellOrders from './components/UserSellOrders'
import UserCashAccounts from './components/UserCashAccounts'
import { formatCashAccountAndCashTransactions } from '../../utils/formatUtils/utils'
import UserSellOffers from './components/UserSellOffers'
import UserBuyOrders from './components/UserBuyOrders'
import UserFeatures from './components/UserFeatures'
import PurchaseCredits from './components/PurchaseCredits'
import RecurringInvestments from './components/RecurringInvestments'
import { debounce } from 'lodash'
import Select from '../assets/components/Select'
import { components } from 'react-select'

const Option = (props: any) => {
  const {
    data: { id, value },
  } = props
  return (
    <>
      <components.Option {...props}>
        {value} (id: {id})
      </components.Option>
    </>
  )
}

const UserDetail: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [openTransaction, setOpenTransaction] = useState(false)
  const [openCredits, setOpenCredits] = useState(false)
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [tab, setTab] = useState('Buy Orders')
  const { id }: any = useParams()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const {
    user,
    ordersColumns,
    holdingsColumns,
    sellOrderColumns,
    sellOfferColumns,
    error,
    errorMessage,
  } = useAppSelector((state: RootState) => state.users)

  const methods = useForm()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
    control,
    watch,
    setValue,
  } = methods

  useEffect(() => {
    const fetchData = async () => {
      const {
        payload: { rows },
      } = await dispatch(getUser({ id }))
      const user = rows[0] || {}
      if (user && user.riaFirmId) {
        await dispatch(getRIAFirm({ id: user.riaFirmId }))
      }
      if (user && user.userId && user.riaFirmId) {
        await dispatch(getRIALinkedClients({ id: user.userId }))
      }
      if (user && user.riaUserId) {
        await dispatch(getParentRIAUser({ id: user.riaUserId }))
      }
      if (user) {
        await dispatch(getTrustedContact({ id }))
        await dispatch(getAllSectors())
        await dispatch(getUserSectors({ id }))
      }
      await dispatch(getCountries())
      setLoading(false)
    }
    fetchData()
  }, [id, dispatch])

  useEffect(() => {
    reset(user)
    if (!user?.accreditedCountry) {
      setValue('accreditedCountry', '')
    }
    if (!user?.citizenshipCountry) {
      setValue('citizenshipCountry', '')
    }
  }, [user, reset, setValue])

  useEffect(() => {
    return () => {
      dispatch(resetError())
      dispatch(resetUser())
    }
  }, [dispatch])

  const onSubmit = async (data: any) => {
    setBtnLoading(true)
    if (data.sameAddress) {
      const primaryAdd = _.find(data.addresses, (a) => a.type === 'ADDRESS')
      const mailingAdd = { ...primaryAdd, type: 'MAILING' }
      data.addresses = [primaryAdd, mailingAdd]
    }
    if (!watch('addresses')) {
      data.addresses = [
        {
          city: '',
          country: '',
          state: '',
          street1: '',
          street2: '',
          type: 'ADDRESS',
          zip: '',
        },
        {
          city: '',
          country: '',
          state: '',
          street1: '',
          street2: '',
          type: 'MAILING',
          zip: '',
        },
      ]
    }
    const res = await dispatch(saveUser({ id, data: { ...user, ...data } }))
    if (res && res.payload === 200) {
      dispatch(resetError())

      const trustedContact = data?.trustedContact
      if (
        trustedContact?.firstName &&
        trustedContact?.lastName &&
        trustedContact?.relationship &&
        trustedContact?.phone
      ) {
        if (!trustedContact.trustedContactId)
          trustedContact.trustedContactId = -1
        await dispatch(
          saveTrustedContact({ ...trustedContact, trustedByUserId: id }),
        )
      }

      const {
        payload: { rows },
      } = await dispatch(getUser({ id }))

      const response = rows[0] || {}
      if (response && response.riaFirmId) {
        await dispatch(getRIAFirm({ id: response.riaFirmId }))
      }
      if (trustedContact) {
        await dispatch(getTrustedContact({ id }))
      }
    }
    setBtnLoading(false)
  }

  const confirmDeleteUser = async (deleteReason: string) => {
    setBtnLoading(true)
    if (watch('deletionReason') !== deleteReason) {
      const payload = { ...user, deletionReason: deleteReason }
      await dispatch(saveUser({ id, data: payload }))
    }

    const response = await dispatch(deleteUser({ id: userId }))
    setBtnLoading(false)
    if (response && response.payload === 200) {
      alert('User has been deleted.')
      history.goBack()
    } else {
      setDeleteModal(false)
    }
  }

  const initiateDeleteUser = () => {
    dispatch(resetError())
    setDeleteModal(true)
  }

  const handleChange = (value: any) => {
    if (value) {
      setLoading(true)
      history.push(`/users/${value.id}`)
    }
  }

  const fetchUserData = useCallback(
    async (e: string) => {
      const data = {
        queryParams: JSON.stringify({
          includeTotals: false,
          userId: {
            freeTextSearchType: 'EXACT'
          },
          firstName: {
            freeTextSearchType: 'STARTS_WITH'
          },
          lastName: {
            freeTextSearchType: 'STARTS_WITH'
          },
          fullName: {
            freeTextSearchType: 'STARTS_WITH'
          },
          email: {
            freeTextSearchType: 'STARTS_WITH'
          },
          freeTextSearch: e,
          current: 0,
          length: 10,
          start: 0
        }),
      }
      if (e.length > 0) {
        const response = await dispatch(getUsers({ found: data }))
        const arr = response.payload?.rows || []
        return arr.map((a: any) => {
          return {
            id: a.userId,
            value: a.fullName,
            label: a.fullName,
          }
        })
      }
    },
    [dispatch],
  )

  const loadUserSuggestedOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchUserData(inputValue).then((options: any) => callback(options))
      }, 500),
    [fetchUserData],
  )

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const {
    userId,
    firstName,
    lastName,
    orders,
    holdings,
    linkedClients,
    sellOrders,
    cashAccounts,
    offers,
  } = user || {}

  const renderTabs = () => {
    switch (tab) {
      case 'Buy Orders': {
        return (
          <UserBuyOrders
            id={id}
            items={orders}
            handleClick={(item: any) => history.push(`/orders/${item.orderId}`)}
            columns={ordersColumns}
          />
        )
      }
      case 'Linqto Bucks': {
        return <LinqtoBucks setOpenTransaction={setOpenTransaction} />
      }
      case 'Entities': {
        return <UserEntities />
      }
      case 'Documents': {
        return (
          <Documents btnLoading={btnLoading} setBtnLoading={setBtnLoading} />
        )
      }
      case 'Tags': {
        return <Tag />
      }
      case 'Address': {
        return <Address loading={btnLoading} />
      }
      case 'Linked Clients': {
        let filteredClients: any = []
        if (linkedClients && linkedClients.length) {
          filteredClients = [
            ...linkedClients.filter(
              (client: any) => client.riaUserStatus !== 'REJECTED',
            ),
          ]
        }
        return (
          <LinkItems
            setBtnLoading={setBtnLoading}
            users={filteredClients}
            type="users"
          />
        )
      }
      case 'User MFA': {
        return <UserMFATab />
      }
      case 'Holdings': {
        return (
          <UserHoldings
            id={id}
            holdings={holdings}
            holdingsColumns={holdingsColumns}
          />
        )
      }
      case 'Sell Orders': {
        return (
          <UserSellOrders
            id={id}
            sellOrders={sellOrders}
            sellOrderColumns={sellOrderColumns}
            handleClick={(item: any) =>
              history.push(`/sell_orders/${item.sellOrderId}`)
            }
          />
        )
      }
      case 'Cash Accounts': {
        const cleanCashAccounts =
          formatCashAccountAndCashTransactions(cashAccounts)
        return (
          <UserCashAccounts
            cashAccounts={cleanCashAccounts}
            setLoading={setLoading}
          />
        )
      }
      case 'Sell Offers': {
        return (
          <UserSellOffers
            id={id}
            handleClick={(item: any) =>
              history.push(`/sell_offers/${item.offerId}`)
            }
            offers={offers}
            columns={sellOfferColumns}
          />
        )
      }
      case 'Features': {
        return <UserFeatures />
      }
      case 'Purchase Credits': {
        return (
          <PurchaseCredits
            setOpenCredits={setOpenCredits}
            handleClick={(item: any) => history.push(`/purchase_credits/${item.creditTransactionId}`)}
          />
        )
      }
      case 'Recurring Investments': {
        return (
          <div>
            <RecurringInvestments
              id={id}
              handleClick={(item: any) => history.push(`/recurring_investments/${item.recurringEventId}`)} />
          </div>
        )
      }
    }
  }

  return (
    <>
      <PageLayout>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-4 mt-4">
              <div className="col-md-4">
                <Select
                  options={Option}
                  loadSuggestedOptions={loadUserSuggestedOptions}
                  handleChange={(value: any) => handleChange(value)}
                  id={watch('userId')}
                  isClearable
                  placeholder='Search'
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4 mt-3">
              <div className="d-flex">
                <h3>
                  User No. {userId} - {firstName} {lastName}
                </h3>
                <CButton
                  className="btn-danger ms-4"
                  onClick={initiateDeleteUser}
                >
                  Delete
                </CButton>
              </div>
              <CLoadingButton
                loading={btnLoading}
                className="btn-success"
                type="submit"
                disabled={!isDirty}
              >
                Save
              </CLoadingButton>
            </div>
            <BasicInformation
              loading={btnLoading}
              register={register}
              control={control}
              user={user}
              open={open}
              watch={watch}
              setOpen={setOpen}
              setBtnLoading={setBtnLoading}
            />
            <TabControl tab={tab} setTab={setTab} />
            {renderTabs()}
          </form>
        </FormProvider>
        {error && errorMessage && !errorMessage.includes('403') && (
          <Alert
            color="danger"
            visible={error}
            onClose={() => dispatch(resetError())}
            text={errorMessage}
          />
        )}
        {deleteModal && (
          <DeleteUserModal
            handleClose={() => setDeleteModal(false)}
            visible={deleteModal}
            deleteUser={confirmDeleteUser}
            btnLoading={btnLoading}
          />
        )}
      </PageLayout>
      {open && <VerifyModal setOpen={setOpen} />}
      {openTransaction && (
        <AddTransactionModal
          open={openTransaction}
          header="Add Transaction"
          userId={userId}
          abandonAction={() => setOpenTransaction(false)}
        />
      )}
      {openCredits && (
        <AddPurchaseCreditModal
          open={openCredits}
          header="Add Purchase Credits"
          userId={userId}
          abandonAction={() => setOpenCredits(false)}
        />
      )}
    </>
  )
}

export default UserDetail
