import { CModal, CModalBody, CNav, CNavItem, CNavLink } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import Spinner from '../../../components/Spinner'
import { useAppDispatch, useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import { getPlaidReports } from '../../../features/usersSlice'

const PlaidReportModal: React.FC<any> = ({ id, handleClose }) => {
  const dispatch = useAppDispatch()
  const [tab, setTab] = useState<any>('Bank Income')
  const [loading, setLoading] = useState(true)
  const { plaidReports } = useAppSelector((state: RootState) => state.users)
  const { bankIncomeReport, assetsReports, documentIncomeReport, w2s, form1099s } = plaidReports || {}

  useEffect(() => {
    (async () => {
      const res = await dispatch(getPlaidReports(id))
      const { payload } = res || {}
      const { bankIncomeReport, documentIncomeReport, w2s, form1099s } = payload || {}
      // set default tab based on available report
      if (bankIncomeReport) {
        setTab('Bank Income')
      } else if (documentIncomeReport) {
        setTab('Document Income')
      } else if (w2s && w2s.length > 0) {
        setTab('w2s')
      } else if (form1099s && form1099s.length > 0) {
        setTab('form1099s')
      }
      setLoading(false)
    })()
  }, [id])

  const renderReport = i => {
    let report = ''
    if (i?.type === 'W2') {
      report = w2s?.[i?.index]
    } else if (i?.type === 'Form 1099') {
      report = form1099s?.[i?.index]
    } else if (i?.type === 'Asset Report') {
      report = assetsReports?.[i?.index]
    }
    if (report) {
      return <pre>{JSON.stringify(report, null, 2)}</pre>
    }
  }

  if (loading) {
    return (
      <CModal visible alignment="center">
        <CModalBody>
          <Spinner />
        </CModalBody>
      </CModal>
    )
  }

  return (
    <CModal visible alignment="center" size="xl" onClose={handleClose}>
      <CModalBody>
      {!bankIncomeReport && !documentIncomeReport && assetsReports.length === 0 && <h4>No Data Available</h4>}
        <CNav variant="tabs">
          {!!bankIncomeReport && <CNavItem className="hover">
            <CNavLink
              active={tab === 'Bank Income'}
              onClick={() => setTab('Bank Income')}
              className={`${tab !== 'Bank Income' && 'inactive'}`}
            >
              Bank Income
            </CNavLink>
          </CNavItem>}
          {assetsReports && assetsReports.length > 0 && assetsReports.map((report: any, i: number) => (
            <CNavItem className="hover" key={i}>
              <CNavLink
                active={i === tab?.index}
                onClick={() => setTab({ type: 'Asset Report', index: i })}
                className={`${+tab !== i && 'inactive'}`}
              >
                Asset Report {i + 1}
              </CNavLink>
            </CNavItem>
          ))}
          {w2s && w2s.length > 0 && w2s.map((report: any, i: number) => (
            <CNavItem className="hover" key={i}>
              <CNavLink
                active={i === tab?.index}
                onClick={() => setTab({ type: 'W2', index: i })}
                className={`${+tab !== i && 'inactive'}`}
              >
                W2 ({i + 1})
              </CNavLink>
            </CNavItem>
          ))}
          {form1099s && form1099s.length > 0 && form1099s.map((report: any, i: number) => (
            <CNavItem className="hover" key={i}>
              <CNavLink
                active={i === tab?.index}
                onClick={() => setTab({ type: 'Form 1099', index: i })}
                className={`${+tab !== i && 'inactive'}`}
              >
                Form 1099 ({i + 1})
              </CNavLink>
            </CNavItem>
          ))}
          {documentIncomeReport && <CNavItem className="hover">
            <CNavLink
              active={tab === 'Document Income'}
              onClick={() => setTab('Document Income')}
              className={`${tab !== 'Document Income' && 'inactive'}`}
            >
              Document Income
            </CNavLink>
          </CNavItem>}
        </CNav>
        {tab === 'Bank Income' && bankIncomeReport && <pre>{JSON.stringify(bankIncomeReport, null, 2)}</pre>}
        {tab === 'Document Income' && documentIncomeReport && <pre>{JSON.stringify(documentIncomeReport, null, 2)}</pre>}
        {tab?.type === 'Asset Report' || tab?.type === 'Form 1099' || tab?.type === 'W2' ? renderReport(tab) : null}
      </CModalBody>
    </CModal>
  )
}

export default PlaidReportModal
