import React from 'react'
import PDF from '../assets/pdf.svg'
interface DocumentProps {
  name: string
  url: string
  loading?: boolean
}

const Document: React.FC<DocumentProps> = ({
  name,
  url,
  loading,
}: DocumentProps) => {
  return (
    <div
      className="mb-2 col-md-12 d-flex align-items-center py-2 px-1 rounded border border-primary"
      style={{ cursor: 'pointer', pointerEvents: loading ? 'none' : 'auto' }}
      onClick={() => window.open(url)}
    >
      <img alt="PDF" src={PDF} height={20} width={20} />
      <span className="ms-2 text-overflow">{name}</span>
    </div>
  )
}

export default Document
