import React from 'react'
import {
  CFormSelect,
  CSmartPagination,
  CButton,
  CFormInput,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilFilter } from '@coreui/icons'
import PageCounter from './PageCounter'
import { useAppSelector } from '../../../features/hooks'
import { RootState } from '../../../store'
import Download from './Download'

const Pagination: any = ({
  open,
  setOpen,
  current,
  count,
  length,
  handlePageChange,
  handleLengthChange,
  inputRef,
  freeText,
  handleChangeSubmit,
  start,
  reportId,
  isExportAllowed,
}: any) => {
  const { visualizeReport } = useAppSelector((state: RootState) => state.list)

  return (
    <div className="d-flex justify-content-between align-items-start mt-2">
      <div className="d-flex">
        {!visualizeReport && (
          <CFormInput
            ref={inputRef}
            value={freeText}
            className="me-2"
            placeholder="Search"
            onChange={(e: any) => handleChangeSubmit(e.target.value)}
          />
        )}
        <CButton className="text-nowrap me-2" onClick={() => setOpen(true)}>
          <CIcon icon={cilFilter} />
        </CButton>
        {isExportAllowed && <Download reportId={reportId} />}
      </div>
      {count > 0 ? (
        <div className="d-flex align-items-start">
          <div className="me-2 mt-2">
            <PageCounter count={count} start={start} length={length} />
          </div>
          {!visualizeReport && (
            <>
              <CSmartPagination
                className="hover"
                activePage={current + 1}
                pages={Math.ceil(count / length)}
                onActivePageChange={(item: any) =>
                  handlePageChange && item - 1 !== -1
                    ? handlePageChange(item - 1)
                    : null
                }
              />

              <div className="ms-2">
                <CFormSelect
                  defaultValue={length}
                  onChange={handleLengthChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </CFormSelect>
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default Pagination
