import React, { useState } from 'react'
import { CButton, CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import { formatDecimal, formatToPT, toCamalCase } from '../../utils/formatUtils/utils'
import SubmitButton from '../../components/SubmitButton'
import Select from '../assets/components/Select'
import Note from '../../components/Note'
import WithdrawModal from '../../components/WithdrawModal'
import Document from '../../components/Document'
import GenericTable from '../../components/GenericTable'
import { CashTransactionType } from '../cashAccounts/CashAccountTypes'

const LINKED_ACCOUNTS_COLUMNS = [
  {
    key: 'date',
    name: 'date',
    label: 'Date',
    type: 'Date',
    renderer: 'Date',
  },
  {
    key: 'amount',
    name: 'amount',
    label: 'Amount',
    type: 'Number',
    renderer: 'Money',
  },
  {
    key: 'transactionType',
    name: 'transactionType',
    label: 'Description',
    type: 'String',
    renderer: 'String',
  },
  {
    key: 'balanceType',
    name: 'balanceType',
    label: 'Balance Type',
    type: 'String',
    renderer: 'String',
  },
]

const CashExternalAccountDetailForm: React.FC<any> = ({
  onSubmit,
  btnLoading,
  fileSelectedHandler,
  cashExternalAccount,
  user,
  entity,
  cashAccount,
}) => {
  const [note, setNote] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const me = useAppSelector((state: RootState) => state.user)
  const { register, watch, handleSubmit, control, getValues } = useFormContext()
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'notes',
  })

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const handleUnlink = async () => {
    const data = getValues()
    data.isActive = false
    await onSubmit(data)
  }

  const {
    cashExternalAccountId,
    amlStatus,
    cashTransactions,
    accountNumberSafe,
    source,
    isActive,
    documents,
    achWithdrawAllowed,
    wireWithdrawAllowed,
    totalCompletedDepositsAmount
  } = cashExternalAccount || {}

  const { userId, fullName } = user || {}
  const { entityId, name } = entity || {}
  const { ownerName } = cashAccount || {}

  let sanitizedCashTransactions = []

  if (cashTransactions && cashTransactions.length) {
    sanitizedCashTransactions = cashTransactions.map(
      (transaction: CashTransactionType) => ({
        date: formatToPT(transaction.date, 'MM/DD/YY HH:MM'),
        transactionType: toCamalCase(transaction.transactionType),
        balanceType: toCamalCase(transaction.balanceType),
        amount: `$${transaction.amount.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
      }),
    )
  }

  return (
    <>
      {showModal && (
        <WithdrawModal
          cashExternalAccount={cashExternalAccount}
          handleClose={() => setShowModal(false)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>Cash External Account No. {cashExternalAccountId}</h3>
          <SubmitButton loading={btnLoading} text="Save" />
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>AML Status</CFormLabel>
            <CFormSelect {...register('amlStatus')} disabled={btnLoading}>
              {amlStatus === 'APPROVED' || amlStatus === 'REJECTED' ? (
                <option value={amlStatus}>{toCamalCase(amlStatus)}</option>
              ) : (
                <>
                  <option value="NEEDS_REVIEW">Needs Review</option>
                  <option value="CS_NEEDS_REVIEW">CS Needs Review</option>
                  <option value="APPROVED">Approved</option>
                  <option value="REJECTED">Rejected</option>
                </>
              )}
            </CFormSelect>
          </div>
          <div className="col-md-3">
            <CFormLabel>Created At</CFormLabel>
            <CFormInput
              disabled
              value={formatToPT(watch('createdAt'), 'MM/DD/YY')}
            />
          </div>
          <div className="col-md-3">
            <CFormLabel>Bank Name</CFormLabel>
            <CFormInput {...register('bankName')} disabled />
          </div>
          <div className="col-md-3">
            <CFormLabel>Bank Account Owner</CFormLabel>
            <CFormInput {...register('legalName')} disabled />
          </div>
          <div className="col-md-3">
            <CFormLabel>Cash Account Owner</CFormLabel>
            <CFormInput disabled value={ownerName} />
          </div>
          {userId && (
            <div className="col-md-3">
              <CFormLabel>User</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={`${fullName}`}
                query="users"
                id={userId}
                ref={null}
              />
            </div>
          )}
          {entityId && (
            <div className="col-md-3">
              <CFormLabel>Entity</CFormLabel>
              <Select
                readOnly
                options={Option}
                watchValue={`${name}`}
                query="entities"
                id={entityId}
                ref={null}
              />
            </div>
          )}
          {accountNumberSafe && (
            <div className="col-md-3">
              <CFormLabel>Last 4 of Bank Account</CFormLabel>
              <CFormInput disabled value={accountNumberSafe} />
            </div>
          )}
          <div className="col-md-3">
            <CFormLabel>Link Method</CFormLabel>
            <CFormInput disabled value={source} />
          </div>
          <div className="col-md-3">
            <CFormLabel>Is Linked</CFormLabel>
            <CFormInput disabled value={isActive} />
          </div>
          {isActive && (
            <div className="d-flex flex-column col-md-3">
              <CFormLabel>Unlink Account</CFormLabel>
              <CButton className="w-50" onClick={handleUnlink}>
                Unlink
              </CButton>
            </div>
          )}
          <div className="col-md-3">
            <CFormLabel>Total Completed Deposits</CFormLabel>
            <CFormInput disabled value={formatDecimal(totalCompletedDepositsAmount || 0)} />
          </div>
          {watch('deactivatedAt') && (
            <div className="col-md-3">
              <CFormLabel>Unlink Date</CFormLabel>
              <CFormInput
                disabled
                value={formatToPT(watch('deactivatedAt'), 'MM/DD/YY')}
              />
            </div>
          )}
          {isActive && (wireWithdrawAllowed || achWithdrawAllowed) && (
            <div className="col-md-3 d-flex align-items-end">
              <CButton onClick={() => setShowModal(true)}>Withdraw</CButton>
            </div>
          )}
        </div>
        <div className="d-flex">
          <div className="col-md-6">
            <div className="col-md-12 d-flex align-items-end justify-content-between">
              <div className="d-flex flex-column w-100 me-2">
                <h4>Notes</h4>
                <CFormInput
                  placeholder="Add note..."
                  disabled={btnLoading}
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </div>
              <CButton disabled={!note} type="button" onClick={updateNote}>
                Add
              </CButton>
            </div>
            {fields && fields.length
              ? fields.map((note: any, i: number) => (
                <Note
                  index={note.id}
                  key={note.id}
                  note={note}
                  deleteNote={() => remove(i)}
                />
              ))
              : null}
          </div>
          <div className="col-md-6 mx-4">
            <h4>Documents</h4>
            <div className="row g-3 mb-4 d-flex align-items-center">
              <div className="col-mb-12">
                {documents?.map(({ name, url }: any, i: number) => (
                  <Document
                    key={i}
                    name={name}
                    url={url}
                    loading={btnLoading}
                  />
                ))}
                <div className="d-flex justify-content-between">
                  <CFormInput
                    disabled={btnLoading}
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      fileSelectedHandler(e, 'CASH_EXTERNAL_ACCOUNT')
                    }
                    accept=".pdf, .png, .jpg, .jpeg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h3>Transaction History</h3>
          <GenericTable
            columns={LINKED_ACCOUNTS_COLUMNS}
            items={sanitizedCashTransactions}
          />
        </div>
      </form>
    </>
  )
}

export default CashExternalAccountDetailForm
