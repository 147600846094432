import {
  CFormInput,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/Spinner'
import {
  getCompany,
  resetError,
  saveCompany,
} from '../../features/companiesSlice'
import { useAppDispatch, useAppSelector } from '../../features/hooks'
import PageLayout from '../../layout/PageLayout'
import { RootState } from '../../store'
import Valuation from './partials/Valuation'
import CustomersMarket from './partials/CustomersMarket'
import Management from './partials/Management'
import Control from './partials/Control'
import Checkbox from '../../components/Checkbox'
import Alert from '../../components/Alert'
import FAQs from './partials/FAQs'
import News from './partials/News'
import Messages from './partials/Messages'

const setEmptyFieldsToNull = (obj: any) =>
  Object.keys(obj).forEach((k) =>
    obj[k] === '' || obj[k]?.length === 0 ? (obj[k] = null) : obj[k],
  )

const setEmptyObjectToNull = (obj: any) => {
  if (
    Object.values(obj?.companyDetail?.headquarters).every(
      (x) => x === null || x === '',
    )
  ) {
    delete obj.companyDetail.headquarters
  }

  if (
    Object.values(obj?.companyDetail?.industry).every(
      (x) => x === null || x === '',
    )
  ) {
    delete obj.companyDetail.industry
  }
}

const CompanyDetail: React.FC = () => {
  const { id }: any = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const {
    company,
    error,
    errorMessage,
    loading: btnLoading,
  } = useAppSelector((state: RootState) => state.companies)
  const [control, setControl] = useState<string>('Customers & Market')

  const methods = useForm()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    setValue,
  } = methods

  const [uploadedIconUrl, setUploadedIconUrl] = useState<string>('')
  const [uploadedLogoUrl, setUploadedLogoUrl] = useState<string>('')
  const [hideTab, setHideTab] = useState<boolean>(false)
  const [uploadedInvestmentSummary, setUploadedInvestmentSummary] =
    useState<string>('')

  useEffect(() => {
    dispatch(resetError())
    const getCompanyById = async () => {
      setLoading(true)
      await dispatch(getCompany({ id }))
      setLoading(false)
    }
    getCompanyById()
  }, [dispatch, id])

  useEffect(() => {
    reset(company)
    if (company && !company.companyDetail) {
      setHideTab(true)
    } else {
      setHideTab(false)
    }
  }, [company, reset])

  const handleFileChange = (type = '', e: any) => {
    const files = e.target.files
    const reader = new FileReader()

    if (files.length > 0) {
      reader.readAsDataURL(files[0])

      reader.onload = (e: any) => {
        if (type === 'logo') {
          setUploadedLogoUrl(e.target.result)
          setValue('logoUrl', e.target.result, { shouldDirty: true })
        } else if (type === 'investmentSummary') {
          setUploadedInvestmentSummary(e.target.result)
          setValue('investmentSummary', e.target.result, { shouldDirty: true })
        } else {
          setUploadedIconUrl(e.target.result)
          setValue('iconUrl', e.target.result, { shouldDirty: true })
        }
      }
    }
  }

  const renderTabs = () => {
    switch (control) {
      case 'Customers & Market': {
        return <CustomersMarket />
      }
      case 'Valuation & Funding': {
        return <Valuation />
      }
      case 'Management': {
        return <Management />
      }
      case 'FAQs': {
        return <FAQs />
      }
      case 'News': {
        return <News />
      }
      case 'Messages': {
        return (
          <Messages
            id={id}
          />
        )
      }
    }
  }

  const onSubmit = async (data: any) => {
    if (uploadedIconUrl && uploadedIconUrl.length) {
      data.iconUrl = uploadedIconUrl
    }
    if (uploadedLogoUrl && uploadedLogoUrl.length) {
      data.logoUrl = uploadedLogoUrl
    }
    if (uploadedInvestmentSummary && uploadedInvestmentSummary.length) {
      data.investmentSummaryUrl = uploadedInvestmentSummary
    }
    if (data && data.companyDetail) {
      setEmptyObjectToNull(data)
      setEmptyFieldsToNull(data?.companyDetail)
    }

    const response = await dispatch(saveCompany({ id, data: { ...data } }))
    if (response && response.payload === 200) {
      await dispatch(getCompany({ id }))
      dispatch(resetError())
    } else {
      reset()
    }
  }

  if (loading) {
    return (
      <PageLayout>
        <Spinner />
      </PageLayout>
    )
  }

  const {
    name,
    legalName,
    publicDescription,
    privateDescription,
    status,
    logoUrl,
    orderIndex,
    iconUrl,
    websiteUrl,
    urlName,
    promoFlag,
    vertical,
  } = company || {}

  return (
    <PageLayout>
      {error && errorMessage && (
        <Alert
          color="danger"
          visible={error}
          onClose={() => dispatch(resetError())}
          text={errorMessage}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-between">
            <h3>
              Company No. {id} - {name}
            </h3>
            <div className="d-flex align-items-end justify-content-end">
              <CLoadingButton
                disabled={!isDirty}
                loading={btnLoading}
                className="btn-success"
                type="submit"
              >
                Save
              </CLoadingButton>
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Name</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                type="text"
                {...register('name', { required: 'This field is required.' })}
                defaultValue={name}
              />
              {errors.name && (
                <span className="text-danger">{errors.name.message}</span>
              )}
            </div>
            <div className="d-flex align-items-start col-md-3">
              <div className="w-100">
                <CFormLabel>Logo</CFormLabel>
                <CFormInput
                  disabled={btnLoading}
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  name="logoUrlFile"
                  onChange={(e: any) => handleFileChange('logo', e)}
                />
              </div>
              <img
                style={{
                  marginTop: '1.9rem',
                  maxWidth: '100px',
                  maxHeight: '40px',
                }}
                className="ms-3 rounded"
                alt="logo-url"
                src={uploadedLogoUrl === '' ? logoUrl : uploadedLogoUrl}
              />
            </div>
            <div className="d-flex align-items-start col-md-3">
              <div className="w-100">
                <CFormLabel>Icon</CFormLabel>
                <CFormInput
                  disabled={btnLoading}
                  accept=".png,.jpg,.jpeg"
                  type="file"
                  name="iconUrlFile"
                  onChange={(e: any) => handleFileChange('icon', e)}
                />
              </div>
              <img
                style={{
                  marginTop: '1.9rem',
                  maxWidth: '100px',
                  maxHeight: '40px',
                }}
                className="ms-3 rounded"
                alt="icon-url"
                src={uploadedIconUrl === '' ? iconUrl : uploadedIconUrl}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Legal Name</CFormLabel>
              <CFormInput
                disabled={btnLoading}
                type="text"
                {...register('legalName', {
                  required: 'This field is required.',
                })}
                defaultValue={legalName}
              />
              {errors.legalName && (
                <span className="text-danger">{errors.legalName.message}</span>
              )}
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Public Description</CFormLabel>
              <textarea
                disabled={btnLoading}
                className="form-control"
                rows={3}
                {...register('publicDescription', {
                  required: 'This field is required. ',
                })}
                defaultValue={publicDescription}
              ></textarea>
              {errors.publicDescription && (
                <span className="text-danger">
                  {errors.publicDescription.message}
                </span>
              )}
            </div>
            <div className="col-md-3">
              <CFormLabel>Private Description</CFormLabel>
              <textarea
                disabled={btnLoading}
                className="form-control"
                rows={3}
                {...register('privateDescription', {
                  required: 'This field is required.',
                })}
                defaultValue={privateDescription}
              ></textarea>
              {errors.privateDescription && (
                <span className="text-danger">
                  {errors.privateDescription.message}
                </span>
              )}
            </div>
            <div className="col-md-3">
              <CFormLabel>Order Index</CFormLabel>
              <CFormInput
                type="text"
                disabled={btnLoading}
                {...register('orderIndex')}
                defaultValue={orderIndex}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Website URL</CFormLabel>
              <CFormInput
                type="text"
                disabled={btnLoading}
                {...register('websiteUrl')}
                defaultValue={websiteUrl}
              />
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Promo Flag</CFormLabel>
              <CFormInput
                disabled
                type="text"
                {...register('promoFlag')}
                defaultValue={promoFlag}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>URL Name</CFormLabel>
              <CFormInput
                disabled
                {...register('urlName')}
                defaultValue={urlName}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Status</CFormLabel>
              <CFormInput
                disabled
                {...register('status')}
                defaultValue={status}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Vertical</CFormLabel>
              <CFormSelect
                disabled={btnLoading}
                {...register('vertical')}
                defaultValue={vertical || ''}
              >
                <option value={''}> </option>
                <option value="Aerospace & Defense">Aerospace & Defense</option>
                <option value="Artificial Intelligence">
                  Artificial Intelligence
                </option>
                <option value="Consumer">Consumer</option>
                <option value="Digital Assets">Digital Assets</option>
                <option value="Energy">Energy</option>
                <option value="FinTech">FinTech</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Industrials">Industrials</option>
                <option value="Internet">Internet</option>
                <option value="Software">Software</option>
              </CFormSelect>
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-3">
              <CFormLabel>Origination Share Price</CFormLabel>
              <CFormInput
                {...register('originationSharePrice')}
                disabled={btnLoading}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Origination Maximum Amount</CFormLabel>
              <CFormInput
                {...register('originationMaxAmount')}
                disabled={btnLoading}
              />
            </div>
            <div className="col-md-3">
              <CFormLabel>Origination Minimum Amount</CFormLabel>
              <CFormInput
                {...register('originationMinAmount')}
                disabled={btnLoading}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="row mb-4 w-100">
              <h6>Headquarters</h6>
              <div className="col-sm">
                <CFormLabel>City</CFormLabel>
                <CFormInput
                  {...register('companyDetail.headquarters.city')}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-sm">
                <CFormLabel>State</CFormLabel>
                <CFormInput
                  {...register('companyDetail.headquarters.state')}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-sm">
                <CFormLabel>Country</CFormLabel>
                <CFormInput
                  {...register('companyDetail.headquarters.country')}
                  disabled={btnLoading}
                />
              </div>
            </div>
            <div className="row mb-4 w-100 ps-2">
              <h6>Industry</h6>
              <div className="col-sm">
                <CFormLabel>Industry</CFormLabel>
                <CFormInput
                  {...register('companyDetail.industry.industry')}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-sm">
                <CFormLabel>Sub Industry</CFormLabel>
                <CFormInput
                  {...register('companyDetail.industry.subIndustry')}
                  disabled={btnLoading}
                />
              </div>
              <div className="col-sm">
                <CFormLabel>Sector</CFormLabel>
                <CFormInput
                  {...register('companyDetail.industry.sector')}
                  disabled={btnLoading}
                />
              </div>
            </div>
          </div>
          <div className="row g-3 mb-4">
            <div className="col-md-4">
              <CFormLabel>About Video URL</CFormLabel>
              <CFormInput
                {...register('companyDetail.aboutVideoUrl')}
                disabled={btnLoading}
              />
            </div>
            <div className="col-md-4">
              <CFormLabel>Why Invest Video URL</CFormLabel>
              <CFormInput
                {...register('companyDetail.whyInvestVideoUrl')}
                disabled={btnLoading}
              />
            </div>
            <div className="col-md-4">
              <CFormLabel>Why Invest Description</CFormLabel>
              <CFormInput
                {...register('companyDetail.whyInvestDescription')}
                disabled={btnLoading}
              />
            </div>
          </div>
          <div className="col g-3 mb-4">
            <div className="col-md-4">
              <CFormLabel>Shares to Exclude from Pool Offer</CFormLabel>
              <CFormInput
                {...register('sharesToExcludeFromPoolOffer')}
                disabled={btnLoading}
              />
            </div>
          </div>
          <div className="col g-3 mb-4">
            <Checkbox
              formLabel="Retail Purchase Enabled"
              registerValue="retailPurchaseEnabled"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Buy Enabled"
              registerValue="buyEnabled"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Sell Enabled"
              registerValue="sellEnabled"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Is Visible"
              registerValue="isVisible"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Is Featured"
              registerValue="isFeatured"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Is In Portfolio"
              registerValue="isInPortfolio"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Is Coming Soon"
              registerValue="isComingSoon"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Interested In Buying"
              registerValue="interestedInBuying"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Buy Page Disabled"
              registerValue="buyPageDisabled"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            <Checkbox
              formLabel="Disable Sell Page"
              registerValue="disableSellPage"
              btnLoading={btnLoading}
              style={{ width: 'fit-content', marginBottom: '10px' }}
            />
            {!hideTab && (
              <>
                <Control
                  TABS={[
                    'Customers & Market',
                    'Valuation & Funding',
                    'Management',
                    // 'FAQs',
                    'News',
                    'Messages'
                  ]}
                  control={control}
                  setControl={setControl}
                />
                {renderTabs()}
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </PageLayout>
  )
}

export default CompanyDetail
