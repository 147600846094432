import { CFormInput, CFormLabel } from '@coreui/react-pro'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import SubmitButton from '../../components/SubmitButton'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import CountryDropdown from '../../components/CountryDropdown'
import { RegionDropdown } from 'react-country-region-selector'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Document from '../../components/Document'

const RIAFirmDetailForm: React.FC<any> = ({
  onSubmit,
  btnLoading,
  id,
  uploadDoc,
}: any) => {
  const { riaFirm } = useAppSelector((state: RootState) => state.ria)
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext()

  const handleChange = () => setValue('state', '')

  const { riaFirmId, documents } = riaFirm || {}

  let title = ''
  let docUpload = null

  if (+id === -1) {
    title = 'Create RIA Firm'
  } else {
    title = `RIA Firm No ${riaFirmId}`
    docUpload = (
      <div className="d-flex justify-content-between">
        <CFormInput
          disabled={btnLoading}
          accept=".pdf,.png,.jpg,.jpeg"
          type="file"
          onChange={uploadDoc}
        />
      </div>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-between">
          <h3>{title}</h3>
          <div>
            <SubmitButton loading={btnLoading} text="Save" />
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-6">
            <CFormLabel>Brand Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('name', {
                required: 'This field is required.',
              })}
            />
            {errors.name && (
              <span className="text-danger">{errors.name.message}</span>
            )}
          </div>
          <div className="col-md-6">
            <CFormLabel>Legal Name</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('legalName', {
                required: 'This field is required.',
              })}
            />
            {errors.legalName && (
              <span className="text-danger">{errors.legalName.message}</span>
            )}
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <Controller
              name="country"
              rules={{ required: 'This field is required.' }}
              render={({ field: { onChange, value } }) => (
                <CountryDropdown
                  disabled={btnLoading}
                  name="country"
                  label="Country"
                  ref={null}
                  onChange={(e: any) => {
                    onChange(e)
                    handleChange()
                  }}
                  value={value ?? undefined}
                />
              )}
            />
            {errors.country && (
              <span className="text-danger">{errors.country.message}</span>
            )}
          </div>
          <div className="col-md-4">
            <CFormLabel>Street 1</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('street1', {
                required: 'This field is required.',
              })}
            />
            {errors.street1 && (
              <span className="text-danger">{errors.street1.message}</span>
            )}
          </div>
          <div className="col-md-4">
            <CFormLabel>Street 2</CFormLabel>
            <CFormInput {...register('street2')} disabled={btnLoading} />
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-4">
            <CFormLabel>City</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('city', {
                required: 'This field is required.',
              })}
            />
            {errors.city && (
              <span className="text-danger">{errors.city.message}</span>
            )}
          </div>
          <div className="col-md-4">
            <CFormLabel>Postal Code</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              type="number"
              {...register('zip', {
                required: 'This field is required.',
              })}
            />
            {errors.zip && (
              <span className="text-danger">{errors.zip.message}</span>
            )}
          </div>
          <div className="col-md-4">
            <CFormLabel>State</CFormLabel>
            <Controller
              name="state"
              rules={{ required: 'This field is required.' }}
              render={({ field: { onChange, value } }) => (
                <RegionDropdown
                  disabled={btnLoading}
                  country={watch('country')}
                  classes="form-select"
                  onChange={onChange}
                  value={value ?? undefined}
                />
              )}
            />
            {errors.state && (
              <span className="text-danger">{errors.state.message}</span>
            )}
          </div>
        </div>
        <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>CRD #</CFormLabel>
            <CFormInput
              type="number"
              disabled={btnLoading}
              {...register('finraCrdNumber', {
                required: 'This field is required.',
              })}
            />
            {errors.finraCrdNumber && (
              <span className="text-danger">
                {errors.finraCrdNumber.message}
              </span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Contact Person</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('contactName', {
                required: 'This field is required.',
              })}
            />
            {errors.contactName && (
              <span className="text-danger">{errors.contactName.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Contact Email</CFormLabel>
            <CFormInput
              disabled={btnLoading}
              {...register('contactEmail', {
                required: 'This field is required.',
              })}
            />
            {errors.contactEmail && (
              <span className="text-danger">{errors.contactEmail.message}</span>
            )}
          </div>
          <div className="col-md-3">
            <CFormLabel>Contact Phone Number</CFormLabel>
            <PhoneInput
              control={control}
              disabled={btnLoading}
              name="contactPhone"
              international
              countryCallingCodeEditable={false}
              defaultCountry="US"
              rules={{
                required: 'This field is required',
                validate: (value: string) =>
                  value ? isValidPhoneNumber(value) : null,
              }}
            />
            {errors.contactPhone && (
              <span className="text-danger">
                Please enter a valid phone number.
              </span>
            )}
          </div>
        </div>
        {docUpload && (
          <>
            <CFormLabel>Documents</CFormLabel>
            {documents &&
              documents.length > 0 &&
              documents.map(({ name, url }: any, i: number) => (
                <div key={i} className="col-md-6">
                  <Document name={name} url={url} />
                </div>
              ))}
            <div className="col-md-6">{docUpload}</div>
          </>
        )}
      </form>
    </>
  )
}

export default RIAFirmDetailForm
