import React, { useState } from 'react'
import { CButton, CFormInput, CFormLabel, CFormSelect } from '@coreui/react-pro'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../features/hooks'
import { RootState } from '../../store'
import { formatToPT, toCamalCase } from '../../utils/formatUtils/utils'
import SubmitButton from '../../components/SubmitButton'
import Select from '../assets/components/Select'
import Note from '../../components/Note'
import Document from '../../components/Document'
import { useParams } from 'react-router'

const UpholdAccessDetailForm: React.FC<any> = ({
  onSubmit,
  btnLoading,
  fileSelectedHandler,
  upholdAccessData,
  handleUpdateUpholdAccess,
  user
}) => {
const { id }: any = useParams()
  const [note, setNote] = useState<string>('')
  const me = useAppSelector((state: RootState) => state.user)
  const { register, handleSubmit, control } = useFormContext()
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: 'notes',
  })

  const updateNote = () => {
    prepend({
      firstName: me.firstName,
      notes: note,
      time: Date.now(),
      updated: true,
    })
    setNote('')
  }

  const { amlStatus, createdAt, cashAccountOwnerName, userId, legalName, documents, isActive } = upholdAccessData || {}
  const { fullName } = user || {}

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex justify-content-between">
        <h2>{`Uphold Access No. ${id}`}</h2>
          <SubmitButton loading={handleUpdateUpholdAccess.isLoading} text="Save" />
        </div>
        <div>
          <div className="row g-3 mb-4">
          <div className="col-md-3">
            <CFormLabel>AML Status</CFormLabel>
            <CFormSelect {...register('amlStatus')} disabled={handleUpdateUpholdAccess.isLoading}>
              {amlStatus === 'APPROVED' || amlStatus === 'REJECTED' ? (
                <option value={amlStatus}>{toCamalCase(amlStatus)}</option>
              ) : (
                <>
                  <option value="NEEDS_REVIEW">Needs Review</option>
                  <option value="CS_NEEDS_REVIEW">CS Needs Review</option>
                  <option value="APPROVED">Approved</option>
                  <option value="REJECTED">Rejected</option>
                  <option value="UNKNOWN">Unknown</option>
                </>
              )}
            </CFormSelect>
          </div>
          <div className="col-md-3">
              <CFormLabel className="form-label">Created At </CFormLabel>
              <CFormInput disabled value={formatToPT(createdAt, 'MM/DD/YYYY')} />
            </div>
            <div className="col-md-3">
              <CFormLabel className="form-label">Uphold Legal Name</CFormLabel>
              <CFormInput disabled value={legalName} />
            </div>
          <div className="col-md-3">
              <CFormLabel className="form-label">Cash Account Owner</CFormLabel>
              <CFormInput disabled value={cashAccountOwnerName} />
            </div>
            {userId && (
            <div className="col-md-3">
              <CFormLabel>User</CFormLabel>
              <Select
                readOnly
                watchValue={fullName}
                query="users"
                id={userId}
                ref={null}
              />
            </div>
          )}
            <div className="col-md-3">
              <CFormLabel className="form-label">Link Method</CFormLabel>
              <CFormInput disabled value='UPHOLD' />
            </div>
            <div className='col-md-3'>
              <CFormLabel className="form-label">Is Active</CFormLabel>
              <CFormInput disabled value={isActive} />
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div className="col-md-6">
            <div className="col-md-12 d-flex align-items-end justify-content-between">
              <div className="d-flex flex-column w-100 me-2">
                <h4>Notes</h4>
                <CFormInput
                  placeholder="Add note..."
                  disabled={btnLoading}
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </div>
              <CButton disabled={!note} type="button" onClick={updateNote}>
                Add
              </CButton>
            </div>
            {fields && fields.length
              ? fields.map((note: any, i: number) => (
                <Note
                  index={note.id}
                  key={note.id}
                  note={note}
                  deleteNote={() => remove(i)}
                />
              ))
              : null}
          </div>
          <div className="col-md-6 mx-4">
            <h4>Documents</h4>
            <div className="row g-3 mb-4 d-flex align-items-center">
              <div className="col-mb-12">
                {documents?.map(({ name, url }: any, i: number) => (
                  <Document
                    key={i}
                    name={name}
                    url={url}
                    loading={btnLoading}
                  />
                ))}
                <div className="d-flex justify-content-between">
                  <CFormInput
                    disabled={btnLoading}
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      fileSelectedHandler(e, 'UPHOLD_ACCESSES')
                    }
                    accept=".pdf, .png, .jpg, .jpeg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default UpholdAccessDetailForm
