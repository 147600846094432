import { CFormInput, CFormLabel } from '@coreui/react-pro'
import React from 'react'
import Document from '../../../components/Document'

const DocumentContainer: React.FC<any> = ({
  label,
  documents,
  btnLoading,
  handleChange,
}: any) => {
  return (
    <div className="col-md-6">
      <CFormLabel>{label}</CFormLabel>
      {documents && documents.length
        ? documents.map((doc: any, i: number) => (
            <React.Fragment key={i}>
              <Document name={doc.name} url={doc.url} />
            </React.Fragment>
          ))
        : null}
      <div className="row g-3 mb-4 d-flex align-items-center">
        <div className="col-mb-6 w-50">
          <div className="d-flex justify-content-between">
            <CFormInput
              disabled={btnLoading}
              accept=".pdf,.png,.jpg,.jpeg"
              type="file"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentContainer
