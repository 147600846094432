import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import './scss/style.scss'
import { RootState } from './store'
import { useAppDispatch, useAppSelector } from './features/hooks'
import { getProfile } from './features/userSlice'
import PrivateRoute from './components/PrivateRoute'
import Dashboard from './views/dashboard/Dashboard'

import List from './views/lists/List'
import Transactions from './views/transactions/Transactions'
import ClosedOrders from './views/closedOrders/ClosedOrders'
import TagDetail from './views/tags/TagDetail'
import OriginationCompaniesDetail from './views/originationCompanies/OriginationCompaniesDetail'

import Login from './views/login/Login'
import UserDetail from './views/users/UserDetail'
import EntityDetail from './views/entities/EntityDetail'
import CreateEntity from './views/entities/CreateEntity'
import OrderDetail from './views/orders/OrderDetail'
import SellRequestDetail from './views/sellRequests/SellRequestDetail'
import CompanyDetail from './views/companies/CompanyDetail'
import { getQueries } from './features/userSlice'
import TeamMemberDetail from './views/teamMembers/TeamMemberDetail'
import OfferDetail from './views/offers/OfferDetail'
import AssetDetail from './views/assets/AssetDetail'
import OriginationDetail from './views/originations/OriginationDetail'
import BrokerDetail from './views/brokers/BrokerDetail'
import GlobalErrorModal from './components/GlobalErrorModal'
import Spinner from './components/Spinner'
import SeriesFormDDetail from './views/serieses/FormD/SeriesFormDDetail'
import BlueSkyFeeDetail from './views/serieses/BlueSky/BlueSkyFeeDetail'
import FAQDetail from './views/faqs/FAQDetail'
import RIAFormDetail from './views/ria_firms/RIAFirmDetail'
import CashAccountDetail from './views/cashAccounts/CashAccountDetail'
import CashExternalAccountDetail from './views/cashExternalAccounts/CashExternalAccountDetail'
import SellOrderDetail from './views/sellOrders/SellOrderDetail'
import SellOfferDetail from './views/sellOffers/SellOfferDetail'
import TransactionDetail from './views/transactions/TransactionDetail'
import BankAccountDetail from './views/bankAccounts/BankAccountDetail'
import PurchaseCreditsDetail from './views/purchaseCredits/PurchaseCreditsDetail'
import RecurringInvestmentsDetail from './views/recurringInvestments/RecurringInvestmentsDetail'
import TradeDetail from './views/trades/TradeDetail'

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import CashPaymentOrderDetail from './views/cashPaymentOrders/CashPaymentOrderDetail'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UpholdAccessDetail from './views/upholdAccesses/UpholdAccessDetail'
import CompanyIPODataDetail from './views/companyIpoData/CompanyIPOChoiceDetail'
import UserCompanyIpoChoiceDetail from './views/companyIpoData/UserCompanyIpoChoiceDetail'
import DiscountDetail from './views/discounts/DiscountDetail'
import BundleDetail from './views/bundles/BundleDetail'
import BundleCompanyDetail from './views/bundles/BundleCompanyDetail'
import SeriesDetail from './views/series/SeriesDetail'
import CompanyMessagesDetail from './views/companyMessages/CompanyMessagesDetail'
import CustomDealDetail from './views/customDeals/CustomDeals'

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSuccess: () => {
      return toast.success('Update saved successfully!')
    },
    onError: (error: any) => {
      const errorData = error?.response?.data
      if (errorData?.error === 'IRM_GENERAL' && errorData?.clientMessage) {
        return toast.error(`Something went wrong: ${errorData?.clientMessage}`)
      }
      if (error instanceof Error) {
        return toast.error(`Something went wrong: ${error.message}`)
      }
      return toast.error('Something went wrong. Please try again later')
    },
  }),
  queryCache: new QueryCache({
    onError: (error: any) => {
      const errorData = error?.response?.data
      if (errorData?.error === 'IRM_GENERAL' && errorData?.clientMessage) {
        return toast.error(`Something went wrong: ${errorData?.clientMessage}`)
      }
      if (error instanceof Error) {
        return toast.error(`Something went wrong: ${error.message}`)
      }
      return toast.error('Something went wrong. Please try again later')
    },
  }),
})

const App: React.FC = () => {
  const { isLoggedIn } = useAppSelector((state: RootState) => state.user)
  const { globalError, globalErrorMessage } = useAppSelector(
    (state: RootState) => state.common,
  )
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await dispatch(getProfile())
      setLoading(false)
    }
    fetchData()
  }, [dispatch, history])

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        setLoading(true)
        await dispatch(getQueries())
        setLoading(false)
      }
    }
    fetchData()
  }, [isLoggedIn, dispatch])

  if (loading) {
    return <Spinner />
  }

  if (globalError && globalErrorMessage) {
    return <GlobalErrorModal />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen />
      <ToastContainer theme="colored" autoClose={2000} />
      <Switch>
        <Route exact path="/login" render={() => <Login />} />
        {isLoggedIn && (
          <>
            <PrivateRoute exact path="/users/:id" component={UserDetail} />
            <PrivateRoute exact path="/orders/:id" component={OrderDetail} />
            <PrivateRoute
              exact
              path="/create-entity"
              component={CreateEntity}
            />
            <PrivateRoute exact path="/entities/:id" component={EntityDetail} />
            <PrivateRoute
              exact
              path="/origination_sell_to_linqto_requests/:id"
              component={SellRequestDetail}
            />
            <PrivateRoute
              exact
              path="/origination_companies/:id"
              component={OriginationCompaniesDetail}
            />
            <PrivateRoute
              exact
              path="/companies/:id"
              component={CompanyDetail}
            />
            <PrivateRoute
              exact
              path="/team_members/:id"
              component={TeamMemberDetail}
            />
            <PrivateRoute exact path="/offers/:id" component={OfferDetail} />
            <PrivateRoute exact path="/assets/:id" component={AssetDetail} />
            <PrivateRoute
              exact
              path="/originations/:id"
              component={OriginationDetail}
            />
            <PrivateRoute exact path="/tags/:id" component={TagDetail} />
            <PrivateRoute
              exact
              path="/broker_infos/:id"
              component={BrokerDetail}
            />
            <PrivateRoute
              exact
              path="/series_formd_infos/:id"
              component={SeriesFormDDetail}
            />
            <PrivateRoute
              exact
              path="/series_blue_sky_fees/:id"
              component={BlueSkyFeeDetail}
            />
            <PrivateRoute
              exact
              path="/ria_firms/:id"
              component={RIAFormDetail}
            />
            <PrivateRoute exact path="/faqs/:id" component={FAQDetail} />
            <PrivateRoute
              exact
              path="/cash_accounts/:id"
              component={CashAccountDetail}
            />
            <PrivateRoute
              exact
              path="/cash_external_accounts/:id"
              component={CashExternalAccountDetail}
            />
            <PrivateRoute
              exact
              path="/sell_offers/:id"
              component={SellOfferDetail}
            />
            <PrivateRoute
              exact
              path="/sell_orders/:id"
              component={SellOrderDetail}
            />
            <PrivateRoute
              exact
              path="/transactions/:id"
              component={TransactionDetail}
            />
            <PrivateRoute
              exact
              path="/bank_accounts/:id"
              component={BankAccountDetail}
            />
            <PrivateRoute
              exact
              path="/closed-orders"
              component={ClosedOrders}
            />
            <PrivateRoute
              exact
              path="/cash_payment_orders/:id"
              component={CashPaymentOrderDetail}
            />
            <PrivateRoute
              exact
              path="/purchase_credits/:id"
              component={PurchaseCreditsDetail}
            />
            <PrivateRoute
              exact
              path="/recurring_investments/:id"
              component={RecurringInvestmentsDetail}
            />
            <PrivateRoute
              exact
              path="/uphold_accesses/:id"
              component={UpholdAccessDetail}
            />
            <PrivateRoute
              exact
              path="/company_ipo_datas/:id"
              component={CompanyIPODataDetail}
            />
            <PrivateRoute
              exact
              path="/user_company_ipo_choices/:id"
              component={UserCompanyIpoChoiceDetail}
            />
            <PrivateRoute
              exact
              path="/discounts/:id"
              component={DiscountDetail}
            />
            <PrivateRoute exact path='/company_messages/:id' component={CompanyMessagesDetail} />
            <PrivateRoute exact path='/serieses/:id' component={SeriesDetail} />
            <PrivateRoute exact path="/trades/:id" component={TradeDetail} />
            <PrivateRoute exact path="/bundles/:id" component={BundleDetail} />
            <PrivateRoute exact path='/bundle_companies/:id' component={BundleCompanyDetail} />
            <PrivateRoute exact path='/custom_deals/:id' component={CustomDealDetail} />
            <PrivateRoute exact path="/transactions" component={Transactions} />
            <PrivateRoute exact path="/list" component={List} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/" component={Dashboard} />
          </>
        )}
      </Switch>
    </QueryClientProvider>
  )
}

export default App
